.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 6px solid #B33D5A;
  border-top: 6px solid #77011E;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}