.container {
  composes: Div from '../styles/Div.css'; 
}

.title {
  font-size: 120%;
  margin-bottom: 10px;
  margin-left: 10%;
  margin-right: 10%;
  color: #B33D5A;
}
.wrapper {
  margin-top: 30px;
  background-color: white;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.textContainer {
  text-align: center;
  font-weight: 100;
  margin-bottom: 20px;
  margin-top: 20px;
}
.text, .litext {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20px;
}
@media only screen and (max-width: 600px) {
  .title {
    margin-left: 5%;
    margin-right: 5%;
  }
  .text, .litext {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.nomargin {
  margin-top: 0;
  margin-bottom: 0;
}

.litext {
  text-align: left;
}

.litextcontainer {
  margin-left: 10px;
}