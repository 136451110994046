.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-top-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  border-color: #B33D5A;
}
.containerOpen {
  border-top-width: 5px;
}
.typeButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.selectionCheckboxContainer {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  flex-wrap: wrap;
}
.title {
  align-self: flex-start;
  margin-top: 15px;
  margin-bottom: 3px;
  color: black;
  font-size: 120%;
}
.addRemoveContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.titleRemoveContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  transform: translateY(10px);
}
.closingTimeContainer {  
  align-self: flex-start;
  margin-top: 15px;
}
.datePickerButton {
  width: 300px;
  border-radius: 30px;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #B33D5A;
  background-color: white;
  outline: none
}
.teamSelectionContainer {
  display: flex;
}
.actionButtonsContainer {
  display: flex; 
}

.iconButton {
  composes: button from '../styles/Button.css';
  composes: iconButton from '../styles/Button.css';
  margin-bottom: 0;
}

.hideVisibility {
  visibility: hidden;
}

.iconButton.marginSides {
  margin-left: 15px;
  margin-right: 15px;
}