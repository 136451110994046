.container {
  composes: Div from '../styles/Div.css';
  composes: RowContainer from '../styles/Div.css';
}

.title {
  composes: Title from '../styles/Title.css';
  margin-top: 0;  
}

.title2 {
  composes: Title from '../styles/Title.css';
  margin-right: 10px;
  margin-left: 10px;
}
