.Header {
  background: #FFFFFF;
  color:#B33D5A;
  padding: 8px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
}

.TitleContainer {
  display: flex;
}

.Mainlogo {
  width: 55px;
  height: 55px;
  margin-right: 5px;
}

.Title {
  font-size: 180%;
}
