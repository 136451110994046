.container, .SelectionMenu, .inputWithSelection {
  composes: Div from '../styles/Div.css';
}

.SelectionMenu {
  composes: WrapRowContainer from '../styles/Div.css';
  border-radius: 10px;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 0;
}

.inputWithSelection {
  flex-direction: row;
}

.title {
  composes: Title from '../styles/Title.css';
  margin-top: 0;  
}

.OptionButton {
  composes: button from '../styles/Button.css';
  composes: settingsMenu from '../styles/Button.css';

}

.selectionButton {
  composes: button from '../styles/Button.css';
  flex: none;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 10px;
  flex-direction: row;  
}

.selectionButton:active {
  opacity: 0.6;
}

@media (hover: hover) and (pointer: fine) {
  .selectionButton:hover {
    opacity: 0.5;
  }
}