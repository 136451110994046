.addRemoveContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.removeButton {
  composes: button from '../styles/Button.css';
  composes: iconButton from '../styles/Button.css';
  margin-left: 15px;
}

.addButton {
  composes: button from '../styles/Button.css';
  composes: iconButton from '../styles/Button.css';
  display: flex;
}