.Container {
  background-color: #B33D5A;
  color: white;
  display: flex;
  flex: 1;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}

.settingMenu {
  position: absolute;
  background-color: #B33D5A;
  z-index: 1;
  right: 0;
  transform: translateY(42px);
  display: flex;
  flex-direction: column;
}

.menuButton {
  composes: button from '../styles/Button.css';
  composes: menubar from '../styles/Button.css';
}

@media screen and (max-width: 600px) {
  .menuButton.hideMenuMobile:not(.selectedMenuItem) {
    display: none;
  }
  .menuButton.hideMenuMobile.selectedMenuItem {
    background-color: #B33D5A;
  }
}

.menuButton.selectedMenuItem  {
  background-color: #8B1532;
}

.menubarIcon {
  composes: button from '../styles/Button.css';
  flex: none;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 10px;
  margin-left: 10px;
  flex-direction: row;
  background-color: transparent !important;  
}

.button.menubarIcon:active {
  opacity: 0.6;
}

@media (hover: hover) and (pointer: fine) {
  .button.menubarIcon:hover {
    opacity: 0.5;
  }
}

.settingsMenuButton {
  composes: button from '../styles/Button.css';
  composes: settingsMenu from '../styles/Button.css';
}