.CheckBox {
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  background-color: transparent;
  border-width: 0;
  font-size: 120%;
  outline: none;
  border: none;
  color: #B33D5A;
}

.doubleRow {
  margin-left: 10px;  
}

.noBottomMargin {
  margin-bottom: 0;
}