.container {
  display: flex;
  flex-direction: column;       
  align-items: stretch;
}

.clusterContainer {
  composes: Div from '../styles/Div.css';
  composes: WrapRowContainer from '../styles/Div.css';
}

.clusterButton {
  composes: button from '../styles/Button.css';
  padding-left: 7px;
  padding-right: 7px;  
  margin-right: 5px;
  flex-basis: auto;  
  margin-top: 15px;
}

.darkButton {
  composes: dark from '../styles/Button.css';
}

.description {
  color: #B33D5A;
  font-size: 85%;
  margin: 5px 0;
  text-align: center;        
}

.passwordContainer {
  margin-bottom: 15px;
}

.warningsContainer {
  max-width: 600px;
}

@media only screen and (min-width: 650px) {
  .container {
    width: 600px;
    align-items: center; 
  }
  .container.doubleRowLeft, .container.doubleRowRight {
    width: 290px;
  }
}
