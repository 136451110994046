.container {
  composes: Div from '../styles/Div.css';
}

.marginBottom {
  margin-bottom: 15px;
}

.passwordInput {
  margin-top: 15px;
  margin-bottom: 15px;
}
