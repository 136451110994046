.container {
  composes: Div from '../styles/Div.css';
}
.title {
  margin-right: 10px;
}
.headline {
  flex: none;
  color: black;
  font-size: 150%;
  text-align: center;
}

@media only screen and (min-width: 450px) {
  .title {
    margin-right: 0;
  }
  headline {
    flex: 1;
  }
}