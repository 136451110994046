.container {
  composes: Div from '../styles/Div.css'; 
}

.headline {
  color: black;
  font-size: 120%;
  text-align: center;
}
.link {
  color: black;
  font-size: 100%;
}