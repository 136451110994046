.Button {
  background-color: unset;
  border: unset;
  color: unset;
  font: unset;
  padding: unset;
}

.Button:active {
  opacity: 0.5;
}
.Button:focus {
  outline: 0;
}

@media (hover: hover) and (pointer: fine) {
  .Button:hover:not(:disabled) {
    opacity: 0.5;
  }
}

/* TODO: replace MobileMenubar stuff */
.CurrentMenuItem {
  animation-name: smoothup;
  animation-duration: 250ms;
}

@keyframes smoothup {
  from {transform: translateY(0.4rem);}
  to {transform: translateY(0);}
}

.FadeIcon {
  opacity: 0.6;  
  transform: translateY(0.4rem);
}
