.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  margin-left: 10px;
  margin-right: 10px;
}
.centerHelpContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.title {
  text-align: center;
  color: black;
  font-size: 100%;
  margin-bottom: 15px;
  max-width: 550px;
  margin-left: 20px;
  margin-right: 20px;
}
.orTitle {
  font-size: 120%;
}
.description {
  color: #B33D5A;
  margin: 5px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}
.footer {
  width: 100%;
  background-color: #B33D5A;
  color: white;
  padding-bottom: 100px;
  padding-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.footerItem {
  color: white;
  text-align: center;
}
.footerMiddle {
  margin-top: 10px;
  margin-bottom: 10px;
}
.footerPointer {
  cursor: pointer;
}
.footerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: stretch;
}

@media only screen and (min-width: 650px) {
  .container {
    align-items: center; 
  }
}
