.TextArea {
  min-width: 0;
  display: flex;
  flex: 1;
  border-radius: 10px;
  border-width: 2px;
  padding: 10px;
  border-color: #B33D5A;
  border-style: solid;
  color: black;
  text-align: center;
  outline: none;
}