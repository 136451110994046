.container {
  composes: Div from '../styles/Div.css';
  margin-bottom: 15px;
}

.button {
  composes: button from '../styles/Button.css';
}

.dark {
  composes: dark from '../styles/Button.css';
}

.linkButton {
  margin-top: 15px;
  margin-left: 0;
}

.marginTopButton {
  margin-top: 15px;
}

.container.doubleRowRight {
  margin-left: 10px;
}

.container.doubleRowLeft {
  margin-right: 10px;
}

@media only screen and (min-width: 650px) {
  .container.doubleRowLeft, .container.doubleRowRight {
    width: 290px;
  }
}

@media only screen and (max-width: 600px) {
  .mobileSaveContainer {  
    position: fixed;
    bottom: 0;
    left: 0;    
    z-index: 2000;
    display: flex;
    flex: 1;    
    width: 100%;  
    transform: translateY(0.95rem);  
  }
  .mobileSaveButton {  
    border-radius: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 120%;
  }
  .mobileMenubarVisible {
    bottom: 3.25rem;
  }
}
