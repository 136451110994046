.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.allowButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
