.container {
  composes: Div from '../styles/Div.css';
  composes: RowContainer from '../styles/Div.css';
}

.title {
  composes: Title from '../styles/Title.css';  
}

.selectUserButton {
  composes: button from '../styles/Button.css';
  display: flex;
  justify-content: center;
}

.dark {
  composes: dark from '../styles/Button.css';
}

.removeUserButton {
  composes: button from '../styles/Button.css';
  display: flex;
  flex: none;
  align-self: stretch;
  justify-content: center;
  width: 100px;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 10px;
}
