.Input {
  min-width: 0;
  display: flex;
  flex: 1;
  border-radius: 10px;
  padding: 10px;
  border-color: #B33D5A;
  border-style: solid;
  color: black;
  text-align: center;
  outline: none;
}

input.resultOption {
  width: 100px;
}

input.resultScore {
  max-width: 100px;
}

input.ScoreLimits {
  max-width: 80px;
}

input.InputOddOption {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
