.container {
  composes: Div from '../styles/Div.css';
  composes: RowContainer from '../styles/Div.css';
}

.button {
  composes: button from '../styles/Button.css';  
  display: flex;
  flex: 4;
  justify-content: center;
}

.dark {
  composes: dark from '../styles/Button.css';
}

.title {
  composes: Title from '../styles/Title.css';
  margin-top: 0;  
}

.winTitle {
  composes: Title from '../styles/Title.css';
  margin-left: 10px;
  margin-right: 10px;
}
