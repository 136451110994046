.button {
  flex: 1;
  border-radius: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0px;
  flex-direction: column;
  align-items: center;
  outline: none;
  border: none;
  background-color: #B33D5A;
  color: white;
}

.button:active:not(:disabled) {
  background-color: #8B1532;
}

@media (hover: hover) and (pointer: fine) {
  .button:hover:not(:disabled) {
    background-color: #DB6582;
  }
}

.button.dark {
  background-color: #8B1532;
}

.button.dark:active:not(:disabled) {
  background-color: #77011E;
}

@media (hover: hover) and (pointer: fine) {
  .button.dark:hover:not(:disabled) {
    background-color: #DB6582;
  }
}

.button.iconButton {
  flex: none;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  flex-direction: row;
  background-color: transparent !important;
  color: #B33D5A;
}

.button.iconButton:active {
  color: #8B1532;
}

@media (hover: hover) and (pointer: fine) {
  .button.iconButton:hover {
    color: #DB6582;
  }
}

.button.iconButton.dark {
  color: #8B1532;
}

.button.iconButton.dark:active {
  color: #77011E;
}

@media (hover: hover) and (pointer: fine) {
  .button.iconButton.dark:hover {
    color: #B33D5A;
  }
}

.menubar, .settingsMenu {
  border-radius: 0;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 120%;
}

.settingsMenu {
  white-space: nowrap;
}