.Div {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@media only screen and (min-width: 650px) {
  .Div {
    width: 600px;
  }
}

.RowContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.WrapRowContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 15px;   
}
