    
.closing-date-button {        
    width: 300px;
    border-radius: 30px;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ffffff;
    background-color: #B33D5A;
    font-size: 100%;
    outline: none;
    border: none;
}